.vds-video-layout {
  --video-brand: hsl(0, 0%, 96%);
  /* Layout: https://vidstack.io/docs/player/components/layouts/default-layout#video-layout  */
  /* Components: https://www.vidstack.io/docs/player/components/layouts/default-layout#components */
}

.vds-audio-layout {
  --audio-brand: hsl(0, 0%, 96%);
  /* Layout: https://vidstack.io/docs/player/components/layouts/default-layout#audio-layout */
  /* Components: https://www.vidstack.io/docs/player/components/layouts/default-layout#components */
}

.vds-video-layout {
  --video-controls-hide-delay: 500ms !important;
}

/* Target the duration that appears at the start */
.vds-start-duration {
  display: none !important;
}