.trickDropdown {
    background-color: #DAE0E0;
    border-color: #DAE0E0;
}

input[type=checkbox]:after {
    content: "";
    display: block;
    opacity: 1;
}

input[type=checkbox]:checked:after {
    border: 8px solid black;
    content: "";
}