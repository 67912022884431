.skill-button {
    --background: var(--primary);
    --background-activated: var(--primary);
    --background-hover: var(--primary);
    --border-color: var(--primary-secondary);
    --border-style: solid;
    --border-width: 2px;
    --border-radius: 0.5rem;
    --color: var(--primary-alt);
    --box-shadow: none;
    height: auto;
    transition: all 0.3s ease;
}

.skill-button-selected {
    --border-color: var(--electric-blue) !important;
    --box-shadow: 0 0 10px var(--electric-blue), 0 0 10px var(--electric-blue), inset 0 0 8px var(--electric-blue) !important;
}

.skill-button ion-icon {
    font-size: 24px;
}