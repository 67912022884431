.clear-button {
    background-color: #DCBDC1;
    border-color: #DCBDC1;
    color: #000000
}

.circle-button {
    background-color: #DAE0E0;
    border-color: #DAE0E0;
    color: #000000;
}

.search-filter-button {
    background-color: #5963A7;
    border-color: #5963A7;
    color: #FFFFFF
}