.sport-button {
    --background: var(--primary);
    --background-activated: var(--primary);
    --background-hover: var(--primary);
    --border-style: solid;
    --border-width: 2px;
    --border-radius: 0.5rem;
    --color: var(--primary-alt);
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    height: auto;
    transition: all 0.3s ease;
    overflow: visible !important;
}

.sport-button-disabled {
    opacity: 0.5;
    --border-color: var(--primary-secondary) !important;
    --box-shadow: none !important;
}

.sport-button-disabled:hover {
    --border-color: var(--primary-secondary) !important;
    --box-shadow: none !important;
}

.checkmark-icon {
    position: absolute;
    top: -1rem !important;
    right: -1rem !important;
    border-radius: 50%;
    padding: 2px;
    z-index: 1;
}