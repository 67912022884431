/* Add this to your global CSS file or component-specific CSS */

.custom-searchbar {
    --background: var(--primary);
    --color: var(--primary-alt);
    --placeholder-color: var(--primary-secondary);
    --icon-color: var(--primary-secondary);
    --clear-button-color: var(--primary-secondary);
}

.custom-list {
    background: transparent;
}

.custom-item {
    --background: rgba(255, 255, 255, 0.05);
    --background-hover: rgba(255, 255, 255, 0.1);
    --border-color: var(--highlight-secondary);
    margin-bottom: 8px;
    border-radius: 8px;
}

.custom-item-selected {
    --background: rgba(255, 106, 213, 0.1);
    --background-activated: rgba(255, 106, 213, 0.2);
    --background-hover: rgba(255, 106, 213, 0.15);
    --border-color: var(--neon-pink);
    margin-bottom: 8px;
    border-radius: 8px;
}


ion-toolbar {
    --background: var(--primary);
    --color: var(--primary-alt);
}

/* You might need to add these to override Ionic's default styles */
ion-item {
    --ion-item-background: transparent;
}

ion-list {
    background: transparent;
}

.bg-grid-pattern {
    --background: var(--primary);
    --background-image: var(--grid-pattern);
    --background-size: 20px 20px;
    background-image: var(--grid-pattern);
    background-size: 20px 20px;
    min-height: 120%;
}