:root {
    --primary: #000000;
    --primary-alt: #FFFFFF;
    --primary-secondary: #DAE0E0;
    --highlight: #5861AC;
    --highlight-alt: #324E80;
    --highlight-secondary: #8FBC8F;
    --highlight-danger: #CD5C5C;
    --neon-pink: #ff6ad5;
    --electric-blue: #0affff;
    --grid-pattern: linear-gradient(to right, rgba(218, 224, 224, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(218, 224, 224, 0.1) 1px, transparent 1px);
}

ion-content.welcome-page {
    --background: radial-gradient(circle, var(--highlight) 0%, var(--highlight-alt) 50%, var(--primary) 100%);
}

@keyframes sparkHorizontal {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(2100%);
    }
}

@keyframes sparkVertical {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(2100%);
    }
}

.ion-datetime-custom {
    --background: var(--primary);
    --wheel-highlight-background: rgba(10, 255, 255, 0.2);
    --wheel-fade-background-rgb: 0, 0, 0;
    --wheel-item-color: var(--primary-alt);
    --wheel-item-font-size: 20px;
    --wheel-column-padding: 10px;
    --backdrop-opacity: 0.8;
}

.ion-datetime-custom::part(wheel-item) {
    color: var(--electric-blue);
}

.ion-datetime-custom::part(wheel-item active) {
    color: var(--primary-alt);
}

.ion-datetime-custom::part(time-button active),
.ion-datetime-custom::part(year-button active),
.ion-datetime-custom::part(month-year),
.ion-datetime-custom::part(day-button active) {
    color: var(--primary-alt);
}

.ion-datetime-custom::part(time-button),
.ion-datetime-custom::part(year-button),
.ion-datetime-custom::part(day-button) {
    color: var(--primary-secondary);
}

.bg-grid-pattern {
    --background: var(--primary);
    --background-image: var(--grid-pattern);
    --background-size: 20px 20px;
    background-image: var(--grid-pattern);
    background-size: 20px 20px;
    min-height: 100%;
}

.neon-text {
    color: var(--primary-alt);
    text-shadow: 0 0 5px var(--primary-alt),
    0 0 10px var(--primary-alt),
    0 0 20px var(--primary-alt),
    0 0 40px var(--electric-blue),
    0 0 80px var(--electric-blue),
    0 0 90px var(--electric-blue),
    0 0 100px var(--electric-blue),
    0 0 150px var(--electric-blue);
}

ion-input.neon-text::part(native) {
    color: var(--primary-alt);
    text-shadow: 0 0 5px var(--primary-alt),
    0 0 10px var(--primary-alt),
    0 0 20px var(--primary-alt),
    0 0 40px var(--electric-blue),
    0 0 80px var(--electric-blue);
}

.border-electric-blue {
    border-color: var(--electric-blue);
}

.bg-transparent {
    background-color: transparent;
}