.circle-button {
  background-color: #B8B8B8;
  border-color: #B8B8B8;
  background: #B8B8B8;
  cursor: pointer;
}

.btn-1 {
  animation-name: fadeIn, slideIn;
  animation-duration: 1s, 1s;
  animation-iteration-count: 1;
}

.btn-2 {
  animation-name: fadeIn, slideIn;
  animation-duration: 3s, 1s;
  animation-iteration-count: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slideIn {
  from {
    margin-top: -5%;
  }

  to {
    margin-top: 0%;
  }
}