.map-container {
    height: 100vh;
    width: 100vw;
}

.marker {
    display: block;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
}

.mapboxgl-popup {
    color: black;
}

.mapboxgl-popup-content {
    font-family: 'Open Sans', sans-serif;
}

/* For popup tip color, need all these bc direction changes */

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: #1a1b1e !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: #1a1b1e !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: #1a1b1e !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: #1a1b1e !important;
}

.circle-button {
    background-color: white;
    border-color: white;
}

.mapboxgl-ctrl-top-right {
    top: 2rem !important;
    /* Adjust this value to move controls lower */
}

.world-web-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

/* Ensure Mapbox controls are visible */
.mapboxgl-control-container {
    z-index: 1;
}