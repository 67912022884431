/* Google Font */
@import url("https://fonts.googleapis.com/css2?family=Mukta+Mahee:wght@700&display=swap");

* {
    font-family: "Mukta Mahee", sans-serif;
}

hr {
    border-top: 10px solid black;
}

img {
    width: 100px;
}

@media (prefers-color-scheme: dark) {

    /*
     * Dark Colors
     * -------------------------------------------
     */
    body {
        --ion-color-primary: #428cff;
        --ion-color-primary-rgb: 66, 140, 255;
        --ion-color-primary-contrast: #ffffff;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-color-primary-shade: #3a7be0;
        --ion-color-primary-tint: #5598ff;

        --ion-color-secondary: #50c8ff;
        --ion-color-secondary-rgb: 80, 200, 255;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #46b0e0;
        --ion-color-secondary-tint: #62ceff;

        --ion-color-tertiary: #6a64ff;
        --ion-color-tertiary-rgb: 106, 100, 255;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #5d58e0;
        --ion-color-tertiary-tint: #7974ff;

        --ion-color-success: #2fdf75;
        --ion-color-success-rgb: 47, 223, 117;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0, 0, 0;
        --ion-color-success-shade: #29c467;
        --ion-color-success-tint: #44e283;

        --ion-color-warning: #ffd534;
        --ion-color-warning-rgb: 255, 213, 52;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0bb2e;
        --ion-color-warning-tint: #ffd948;

        --ion-color-danger: #ff4961;
        --ion-color-danger-rgb: 255, 73, 97;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #e04055;
        --ion-color-danger-tint: #ff5b71;

        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244, 245, 248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;

        --ion-color-medium: #989aa2;
        --ion-color-medium-rgb: 152, 154, 162;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0, 0, 0;
        --ion-color-medium-shade: #86888f;
        --ion-color-medium-tint: #a2a4ab;

        --ion-color-light: #222428;
        --ion-color-light-rgb: 34, 36, 40;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #1e2023;
        --ion-color-light-tint: #383a3e;
    }

    /*
     * iOS Dark Theme
     * -------------------------------------------
     */
    .ios body {
        --ion-background-color: #000000;
        --ion-background-color-rgb: 0, 0, 0;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;

        --ion-toolbar-background: #0d0d0d;

        --ion-item-background: #1c1c1c;
        --ion-item-background-activated: #313131;
    }

    /*
     * Material Design Dark Theme
     * -------------------------------------------
     */
    .md body {
        --ion-background-color: #121212;
        --ion-background-color-rgb: 18, 18, 18;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-border-color: #222222;

        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;

        --ion-item-background: #1a1b1e;
    }

    ion-title.title-large {
        --color: white;
    }
}

@media (prefers-color-scheme: light) {

    /*
     * Dark Colors
     * -------------------------------------------
     */
    body {
        --ion-color-primary: #428cff;
        --ion-color-primary-rgb: 66, 140, 255;
        --ion-color-primary-contrast: #ffffff;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-color-primary-shade: #3a7be0;
        --ion-color-primary-tint: #5598ff;

        --ion-color-secondary: #50c8ff;
        --ion-color-secondary-rgb: 80, 200, 255;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #46b0e0;
        --ion-color-secondary-tint: #62ceff;

        --ion-color-tertiary: #6a64ff;
        --ion-color-tertiary-rgb: 106, 100, 255;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #5d58e0;
        --ion-color-tertiary-tint: #7974ff;

        --ion-color-success: #2fdf75;
        --ion-color-success-rgb: 47, 223, 117;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0, 0, 0;
        --ion-color-success-shade: #29c467;
        --ion-color-success-tint: #44e283;

        --ion-color-warning: #ffd534;
        --ion-color-warning-rgb: 255, 213, 52;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0bb2e;
        --ion-color-warning-tint: #ffd948;

        --ion-color-danger: #ff4961;
        --ion-color-danger-rgb: 255, 73, 97;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #e04055;
        --ion-color-danger-tint: #ff5b71;

        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244, 245, 248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;

        --ion-color-medium: #989aa2;
        --ion-color-medium-rgb: 152, 154, 162;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0, 0, 0;
        --ion-color-medium-shade: #86888f;
        --ion-color-medium-tint: #a2a4ab;

        --ion-color-light: #222428;
        --ion-color-light-rgb: 34, 36, 40;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #1e2023;
        --ion-color-light-tint: #383a3e;
    }

    /*
     * iOS Dark Theme
     * -------------------------------------------
     */
    .ios body {
        --ion-background-color: #000000;
        --ion-background-color-rgb: 0, 0, 0;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;

        --ion-toolbar-background: #0d0d0d;

        --ion-item-background: #1c1c1c;
        --ion-item-background-activated: #313131;
    }

    /*
     * Material Design Dark Theme
     * -------------------------------------------
     */
    .md body {
        --ion-background-color: #121212;
        --ion-background-color-rgb: 18, 18, 18;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-border-color: #222222;

        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;

        --ion-item-background: #1a1b1e;
    }

    ion-title.title-large {
        --color: white;
    }
}

ion-content ion-toolbar {
    --background: translucent;
}

:root {
    --ion-color-highlight: #5963A7;
    --ion-color-highlight-rgb: 89, 99, 167;
    --ion-color-highlight-contrast: #ffffff;
    --ion-color-highlight-contrast-rgb: 255, 255, 255;
    --ion-color-highlight-shade: #5963A7;
    --ion-color-highlight-tint: #5963A7;

    --ion-color-highlight-light: #7a82b9;
    /* 20% lighter */
    --ion-color-highlight-rgb-light: 122, 130, 185;
    --ion-color-highlight-contrast-light: #ffffff;
    --ion-color-highlight-contrast-rgb-light: 255, 255, 255;
    --ion-color-highlight-shade-light: #7a82b9;
    --ion-color-highlight-tint-light: #7a82b9;

    --ion-color-highlight-alternative: #324E80;
    --ion-color-highlight-alternative-rgb: 50, 78, 128;
    --ion-color-highlight-alternative-contrast: #ffffff;
    --ion-color-highlight-alternative-contrast-rgb: 255, 255, 255;
    --ion-color-highlight-alternative-shade: #324E80;
    --ion-color-highlight-alternative-tint: #324E80;

    --ion-color-highlight-secondary: #8FBC8F;
    --ion-color-highlight-secondary-rgb: 143, 188, 143;
    --ion-color-highlight-secondary-contrast: #ffffff;
    --ion-color-highlight-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-highlight-secondary-shade: #8FBC8F;
    --ion-color-highlight-secondary-tint: #8FBC8F;


    --ion-color-theme: #000000;
    --ion-color-theme-rgb: 0, 0, 0;
    --ion-color-theme-contrast: #ffffff;
    --ion-color-theme-contrast-rgb: 255, 255, 255;
    --ion-color-theme-shade: #000000;
    --ion-color-theme-tint: #000000;

    --ion-color-theme-secondary: #FFFFFF;
    --ion-color-theme-secondary-rgb: 255, 255, 255;
    --ion-color-theme-secondary-contrast: #000000;
    --ion-color-theme-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-theme-secondary-shade: #FFFFFF;
    --ion-color-theme-secondary-tint: #FFFFFF;

    --ion-color-theme-alternative: #DAE0E0;
    --ion-color-theme-alternative-rgb: 218, 224, 224;
    --ion-color-theme-alternative-contrast: #251f1f;
    --ion-color-theme-alternative-contrast-rgb: 37, 31, 31;
    --ion-color-theme-alternative-shade: #DAE0E0;
    --ion-color-theme-alternative-tint: #DAE0E0;

    --ion-color-paypal: #DAE0E0;
    --ion-color-paypal-rgb: 218, 224, 224;
    --ion-color-paypal-contrast: #251f1f;
    --ion-color-paypal-contrast-rgb: 37, 31, 31;
    --ion-color-paypal-shade: #DAE0E0;
    --ion-color-paypal-tint: #DAE0E0;


}

.ion-color-favorite {
    --ion-color-base: var(--ion-color-highlight);
    --ion-color-base-rgb: var(--ion-color-highlight-rgb);
    --ion-color-contrast: var(--ion-color-highlight-contrast);
    --ion-color-contrast-rgb: var(--ion-color-highlight-contrast-rgb);
    --ion-color-shade: var(--ion-color-highlight-shade);
    --ion-color-tint: var(--ion-color-highlight-tint);
}

.ion-color-favorite-secondary {
    --ion-color-base: var(--ion-color-highlight-alternative);
    --ion-color-base-rgb: var(--ion-color-highlight-alternative-rgb);
    --ion-color-contrast: var(--ion-color-highlight-alternative-contrast);
    --ion-color-contrast-rgb: var(--ion-color-highlight-alternative-contrast-rgb);
    --ion-color-shade: var(--ion-color-highlight-alternative-shade);
    --ion-color-tint: var(--ion-color-highlight-alternative-tint);
}

.ion-color-highlight-secondary {
    --ion-color-base: var(--ion-color-highlight-secondary);
    --ion-color-base-rgb: var(--ion-color-highlight-secondary-rgb);
    --ion-color-contrast: var(--ion-color-highlight-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-highlight-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-highlight-secondary-shade);
    --ion-color-tint: var(--ion-color-highlight-secondary-tint);
}

.ion-color-theme {
    --ion-color-base: var(--ion-color-theme);
    --ion-color-base-rgb: var(--ion-color-theme-rgb);
    --ion-color-contrast: var(--ion-color-theme-contrast);
    --ion-color-contrast-rgb: var(--ion-color-theme-contrast-rgb);
    --ion-color-shade: var(--ion-color-theme-shade);
    --ion-color-tint: var(--ion-color-theme-tint);
}

.ion-color-theme-toolbar {
    --ion-color-base: var(--ion-color-theme);
    --ion-color-base-rgb: var(--ion-color-theme-rgb);
    --ion-color-contrast: var(--ion-color-highlight);
    --ion-color-contrast-rgb: var(--ion-color-theme-contrast-rgb);
    --ion-color-shade: var(--ion-color-theme-shade);
    --ion-color-tint: var(--ion-color-theme-tint);
}

.ion-color-theme-secondary {
    --ion-color-base: var(--ion-color-theme-secondary);
    --ion-color-base-rgb: var(--ion-color-theme-secondary-rgb);
    --ion-color-contrast: var(--ion-color-theme-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-theme-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-theme-secondary-shade);
    --ion-color-tint: var(--ion-color-theme-secondary-tint);
}

.ion-color-theme-alternative {
    --ion-color-base: var(--ion-color-theme-alternative);
    --ion-color-base-rgb: var(--ion-color-theme-alternative-rgb);
    --ion-color-contrast: var(--ion-color-theme-alternative-contrast);
    --ion-color-contrast-rgb: var(--ion-color-theme-alternative-contrast-rgb);
    --ion-color-shade: var(--ion-color-theme-alternative-shade);
    --ion-color-tint: var(--ion-color-theme-alternative-tint);
}

.ion-color-paypal {
    --ion-color-base: var(--ion-color-paypal);
    --ion-color-base-rgb: var(--ion-color-paypal-rgb);
    --ion-color-contrast: var(--ion-color-paypal-contrast);
    --ion-color-contrast-rgb: var(--ion-color-paypal-contrast-rgb);
    --ion-color-shade: var(--ion-color-paypal-shade);
    --ion-color-tint: var(--ion-color-paypal-tint);
}


ion-tab-button {
    --color: var(--ion-color-theme-secondary);
    --ion-text-color: var(--ion-color-theme-secondary);
    --ion-text-color-selected: var(--ion-color-highlight-light);
    --color-selected: var(--ion-color-highlight-light);
}

ion-tab-bar {
    --background: var(--ion-color-theme);
}

.primary-tab-icon {
    --color: var(--ion-color-theme-secondary) !important;
    --ion-text-color: var(--ion-color-theme-secondary) !important;
    --ion-text-color-selected: var(--ion-color-highlight) !important;
    --color-selected: var(--ion-color-highlight) !important;
}

.ion-content-scroll-host {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.ion-content-scroll-host::before,
.ion-content-scroll-host::after {
    position: absolute;

    width: 1px;
    height: 1px;

    content: '';
}

.ion-content-scroll-host::before {
    bottom: -1px;
}

.ion-content-scroll-host::after {
    top: -1px;
}

.map-container {
    height: 400px;
}

.custom_spin {
    width: 100px;
    height: 100px;
}

.blurred_map {
    /* Any browser which supports CSS3 */
    filter: blur(5px);

    /* Webkit in Chrome 52, Safari 9, Opera 39, and earlier */
    -webkit-filter: blur(5px);
}

.map-usermarker__border {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 6px rgba(15, 162, 230, 0.4) solid;
    animation: blink 1s;
    animation-iteration-count: infinite;
    display: flex;
}

.map-usermarker__core {
    background: #1da1f2;
    opacity: 0.9;
    border: 2px white solid;
    width: 18px;
    height: 18px;
    border-radius: 40px;
}

@keyframes blink {
    20% {
        border-color: rgba(0, 0, 0, 0);
    }
}

.ion-text-area-upload-clip {
    --placeholder-color: #949ba7;
    --placeholder-opacity: 1
}

ion-segment .shop {
    --background: #111111;
    --color: #ffffff;
}

.swiper-pagination-bullet-active {
    background: #DAE0E0 !important;
}

body {
    transform: none !important;
}

::-webkit-scrollbar {
    display: none;
}

.neon-button {
    --background: rgba(10, 255, 255, 0.1);
    --background-activated: rgba(10, 255, 255, 0.2);
    --background-hover: rgba(10, 255, 255, 0.15);
    --border-color: var(--electric-blue);
    --border-style: solid;
    --border-width: 2px;
    --box-shadow: 0 0 10px var(--electric-blue), 0 0 10px var(--electric-blue), inset 0 0 8px var(--electric-blue);
    --color: var(--primary-alt);
    --ripple-color: rgba(10, 255, 255, 0.2);
}

.neon-button:disabled {
    --background: rgba(10, 255, 255, 0.05);
    --background-activated: rgba(10, 255, 255, 0.05);
    --background-hover: rgba(10, 255, 255, 0.05);
    --border-color: rgba(10, 255, 255, 0.2);
    --box-shadow: none;
    --color: rgba(255, 255, 255, 0.5);
    /* Make text color dimmer */
    cursor: not-allowed;
    /* Change cursor to indicate disabled state */
    opacity: 0.5;
    /* Make the whole button dimmer */
}

.neon-button-alt {
    --background: rgba(255, 106, 213, 0.1);
    --background-activated: rgba(255, 106, 213, 0.2);
    --background-hover: rgba(255, 106, 213, 0.15);
    --border-color: var(--neon-pink);
    --border-style: solid;
    --border-width: 2px;
    --box-shadow: 0 0 10px var(--neon-pink), 0 0 10px var(--neon-pink), inset 0 0 8px var(--neon-pink);
    --color: var(--primary-alt);
    --ripple-color: rgba(255, 106, 213, 0.2);
}

.neon-button-highlight {
    --background: rgba(255, 106, 213, 0.1);
    --background-activated: rgba(255, 106, 213, 0.2);
    --background-hover: rgba(255, 106, 213, 0.15);
    --border-color: var(--highlight-secondary);
    --border-style: solid;
    --border-width: 2px;
    --box-shadow: 0 0 10px var(--highlight-secondary), 0 0 10px var(--highlight-secondary), inset 0 0 8px var(--highlight-secondary);
    --color: var(--primary-alt);
    --ripple-color: rgba(255, 106, 213, 0.2);
}